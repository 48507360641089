<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage"/>
    <a-card :bordered="false" v-else>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="交易时间">
                <a-range-picker @change="onDateChange"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="开票时间">
                <a-range-picker @change="onInvoiceDateChange"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="箱号">
                <a-input v-model="queryParam.container_no" placeholder="根据箱号搜索" allowClear/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="提单号">
                <a-input v-model="queryParam.bill_no" placeholder="根据提单号搜索" allowClear/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="开票号码">
                <a-input v-model="queryParam.invoice_no" placeholder="根据开票号码搜索" allowClear/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="费用状态">
                <a-select
                  v-model="queryParam.fee_status"
                  style="width: 100%"
                  allowClear
                  placeholder="选择费用状态"
                >
                  <a-select-option v-for="item in feeStatusList" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="堆场">
                <a-select
                  v-model="queryParam.yard_id"
                  style="width: 100%"
                  allowClear
                  placeholder="选择堆场"
                >
                  <a-select-option v-for="item in yardList" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="流水号">
                <a-input v-model="queryParam.trx_id" allowClear placeholder="请至少输入6位流水号进行查询" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="queryList">查询</a-button>
        <a-button type="primary" icon="export" @click="exportSheet">导出</a-button>
        <a-popover
          v-model="popVisible"
          title="选择日期"
          trigger="click"
        >
          <a slot="content">
            <p>
              <a-date-picker
                style="width: 200px"
                placeholder="请选择日期"
                @change="transactionDetailDateChange"
              ></a-date-picker>
            </p>
            <p style="text-align: right">
              <a-button type="primary" @click="handleExportTransactionDetialSheet">确定</a-button>
            </p>
          </a>
          <a-button>每日交易明细</a-button>
        </a-popover>
        <a style="color:red" @click="queryApplyRefund()">待处理退款审核数：{{ this.waitRefundCount }}</a>
      </div>

      <a-alert :message="msg" type="info" style="margin-bottom: 20px;"/>

      <a-table
        row-key="id"
        size="middle"
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="handleChangeTable"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="feeStatus" slot-scope="text, record">
          <a-tag :color="feeStatusColor(record.fee_status)">{{ text }}</a-tag>
        </span>
      </a-table>
    </a-card>
  </keep-alive>
</template>

<script>
import {
  getFeeInfoListByPage,
  listYmsFeeTotal,
  exportFeeInfoListSheet,
  queryWaitAgree,
  exportTransactionDetialSheet
} from '@/api/fms'
import { downloadExcel } from '@/api/common'

export default {
  name: 'YardFeeInfo',
  data() {
    return {
      feeStatusList: [
        { value: 2, name: '已支付' },
        { value: 3, name: '商务待审核' },
        { value: 4, name: '商务审核被驳回' },
        { value: 5, name: '待开票' },
        { value: 7, name: '退款' },
        { value: 6, name: '已开票' },
        { value: 8, name: '未开票' }
      ],
      yardList: [
        { value: 1, name: '浦东堆场' },
        { value: 2, name: '江东堆场' },
        { value: 4, name: '钢四堆场' },
        { value: 5, name: '华东堆场' }
      ],
      queryParam: {},
      waitRefundCount: 0,
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '堆场',
          align: 'center',
          dataIndex: 'yard_name'
        },
        {
          title: '通联支付流水',
          align: 'center',
          dataIndex: 'trx_id',
          customRender: (text) => {
            return text != null ? text : '-'
          }
        },
        {
          title: '小票条码',
          align: 'center',
          dataIndex: 'ticket_code'
        },
        {
          title: '箱号',
          align: 'center',
          dataIndex: 'container_no'
        },
        {
          title: '提单号',
          align: 'center',
          dataIndex: 'bill_no'
        },
        {
          title: '操作类型',
          align: 'center',
          dataIndex: 'record_type'
        },
        {
          title: '费用类型',
          align: 'center',
          dataIndex: 'fee_type',
          customRender: (text) => {
            if (text === 'CUSTOMER') {
              return '客户费用'
            } else if (text === 'REFUND') {
              return '退款'
            }
            return text
          }
        },
        {
          title: '总费用金额',
          align: 'center',
          dataIndex: 'total_money'
        },
        {
          title: '费用状态',
          align: 'center',
          dataIndex: 'fee_status_str',
          scopedSlots: { customRender: 'feeStatus' }
        },
        {
          title: '交易时间',
          align: 'center',
          dataIndex: 'pay_time'
        },
        {
          title: '退款金额',
          align: 'center',
          dataIndex: 'refund_money'
        },
        {
          title: '开票时间',
          align: 'center',
          dataIndex: 'invoice_time'
        },
        {
          title: '开票号码',
          align: 'center',
          dataIndex: 'invoice_no'
        },
        {
          title: '开票金额',
          align: 'center',
          dataIndex: 'invoice_money'
        },
        {
          title: '退票金额',
          align: 'center',
          dataIndex: 'refund_invoice_money'
        },
        {
          title: '线下退款金额',
          align: 'center',
          dataIndex: 'offline_refund_money'
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      msg: null,
      popVisible: false
    }
  },
  mounted() {
    this.loadData()
    this.queryCountWaits()
    this.loadYmsFeeTotal()
  },
  watch: {
    $route: function(newRoute) {
      if (newRoute.name === 'YardFeeInfo' && this.queryParam) {
        newRoute.meta.title = '堆场费用'
        this.$nextTick(_ => {
          this.loadData()
        })
      }
    }
  },
  methods: {
    // 导出每日交易明细表
    handleExportTransactionDetialSheet() {
      if (this.queryParam['pay_start_date'] && this.queryParam['pay_end_date']) {
        exportTransactionDetialSheet(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.queryParam['pay_start_date'] = null
          this.queryParam['pay_end_date'] = null
          this.popVisible = false
        })
      } else {
        this.$message.warning('请选择要导出的日期')
      }
    },
    transactionDetailDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date.startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date.endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['pay_start_date'] = startDate
        this.queryParam['pay_end_date'] = endDate
      } else {
        this.queryParam['pay_start_date'] = null
        this.queryParam['pay_end_date'] = null
      }
    },
    queryCountWaits() {
      queryWaitAgree().then(res => {
        this.waitRefundCount = res
      })
    },
    queryApplyRefund() {
      this.$router.replace({
        name: 'YmsRefundData'
      })
    },
    loadData() {
      getFeeInfoListByPage(this.queryParam).then(res => {
        this.pagination.total = res.total
        this.data = res.list
      })
    },
    loadYmsFeeTotal() {
      listYmsFeeTotal(this.queryParam).then(res => {
        const incomeTotalMoney = parseFloat(res.sum_total_money - res.refund_total_money).toFixed(2)
        const diff = parseFloat(res.sum_total_money - res.refund_total_money - res.sum_invoice_money).toFixed(2)
        this.msg = `支付总金额：${res.sum_total_money}；
          退款总金额：${res.refund_total_money}；
          实收总金额：${incomeTotalMoney}；
          开票总金额：${res.sum_invoice_money}；
          剩余开票金额：${diff}；
          线下退款总金额：${res.offline_refund_total_money}`
      })
    },
    handleChangeTable(page) {
      this.pagination.current = page.current
      this.pagination.pageSize = page.pageSize
      this.queryParam['page'] = page.current
      this.queryParam['page_size'] = page.pageSize
      this.loadData()
    },
    queryList() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.queryParam['page'] = 1
      this.queryParam['page_size'] = 10
      // 分页查询
      this.loadData()
      // 查询待处理退款申请
      this.queryCountWaits()
      // 查询堆场费用总合计
      this.loadYmsFeeTotal()
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['pay_start_date'] = startDate
        this.queryParam['pay_end_date'] = endDate
      } else {
        this.queryParam['pay_start_date'] = null
        this.queryParam['pay_end_date'] = null
      }
    },
    onInvoiceDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['invoice_start_date'] = startDate
        this.queryParam['invoice_end_date'] = endDate
      } else {
        this.queryParam['invoice_start_date'] = null
        this.queryParam['invoice_end_date'] = null
      }
    },
    exportSheet() {
      this.$confirm({
        title: '确认提示',
        content: '确定要导出表格吗?',
        onOk: () => {
          exportFeeInfoListSheet(this.queryParam).then(res => {
            if (res != null) {
              const fileInfo = { fileName: res }
              downloadExcel(fileInfo).then(downRes => {
                const data = downRes.data
                let fileName = ''
                const contentDisposition = downRes.headers['content-disposition']
                if (contentDisposition) {
                  fileName = decodeURI(escape(contentDisposition.split('=')[1]))
                }
                const url = window.URL.createObjectURL(
                  new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                )
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              })
            }
          })
        }
      })
    },
    feeStatusColor(value) {
      switch (value) {
        case 2:
          return '#4cd964'
        case 3:
          return '#faad14'
        case 4:
          return '#ff4d4f'
        case 5:
          return '#1890ff'
        case 6:
          return '#4cd964'
        case 7:
          return '#ff4d4f'
      }
    }
  }
}
</script>

<style scoped>

</style>
